<template>
  <v-app-bar color="primary" fixed width="100%" name="app-bar" class="justify-center">
    <v-app-bar-title>Translate</v-app-bar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>
