<template>
  <v-layout>
    <v-row no-gutters>
      <v-col cols="12">
        <Header />
      </v-col>
      <v-col class="pt-16 mt-4 h-100" cols="12">
        <slot />
      </v-col>
      <v-col cols="12">
        <Footer />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";
export default {
  name: "DefaultLayout",
  components: {Footer, Header}
}
</script>

<style scoped>

</style>
